<template>
  <div id="report-tramite">
    <v-container grid-list-xl fluid>
      <header-title title="Reporte por trámite"></header-title>
      <v-row>
        <v-col cols="12" md="4">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="initDateFormat"
                label="Fecha inicio"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fecha_inicio"
              no-title
              @input="menu1 = false"
              @change="getCheckDate"
              locale="es"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDateFormat"
                label="Fecha fin"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                :disabled="fecha_inicio == ''"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fecha_fin"
              :min="fecha_inicio"
              no-title
              @input="menu2 = false"
              @change="getReportOperador()"
              locale="es"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <!-- <v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showStatistic">
            <v-icon>mdi-image-outline</v-icon>Gráfica
          </v-btn>
        </v-col> -->
      </v-row>
      <div v-if="atendidos.length > 0">
        <v-row>
          <v-col cols="12" md="8">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Tipo de Trámite</th>
                    <th class="text-center">Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in atendidos" :key="index">
                    <td>{{ item.tipotramite }}</td>
                    <td class="text-center">{{ item.cantidad }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="grey lighten-4" v-if="atendidos.length > 0">
                    <th>Total</th>
                    <th class="text-center">
                      {{ total }}
                    </th>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row v-if="atendidos.length > 0">
          <v-col cols="12">
            <highcharts class="chart" :options="chartTramites"></highcharts>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <span class="red--text text--lighten-2">{{ message }}</span>
      </div>
    </v-container>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      timeout="2500"
      >{{ snack.text }}</v-snackbar
    >
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../services/general";
import axios from "axios";
export default {
  name: "report-tramite",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      message: "Seleccione fecha de inicio y fin.",
      loading: false,
      menu1: false,
      menu2: false,
      chartTramites: {},
      atencion: {},
      atendidos: [],
      plataforma_id: "",
      role: "",
      usuario_id: 0,
      fecha_inicio: "",
      fecha_fin: "",
      snack: {
        state: false,
        color: "success",
        mode: "",
        timeout: 2500,
        text: "",
      },
    };
  },

  computed: {
    initDateFormat() {
      return this.fecha_inicio == "" ? "" : this.formatDate(this.fecha_inicio);
    },
    endDateFormat() {
      return this.fecha_fin == "" ? "" : this.formatDate(this.fecha_fin);
    },
    total: function () {
      let sum = 0;
      return this.atendidos.reduce(
        (sum, item) => sum + parseInt(item.cantidad == "" ? 0 : item.cantidad),
        0
      );
    },
  },

  mounted() {
    if (Service.getUser()) {
      this.role = Service.getUser().role;
      if (this.role != 1) {
        if (this.role == 4) {
          this.usuario_id = Service.getUser().uid;
        }
      } else {
        this.$router.replace({ name: "dashboard" });
      }
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getCheckDate() {
      if (this.fecha_inicio > this.fecha_fin && this.fecha_fin != "") {
        this.fecha_fin = this.fecha_inicio;
        this.getReportOperador();
      }
    },
    getReportOperador() {
      this.loadingOperador = true;
      this.atendidos = [];
      axios
        .post(
          Service.getBase() + "reporte/tipo/tramite",
          {
            fecha_inicio: this.fecha_inicio,
            fecha_fin: this.fecha_fin,
            usuario_id: this.usuario_id,
          },
          Service.getHeader()
        )
        .then((response) => {
          this.loadingOperador = false;
          this.atendidos = response.data;
          if (this.atendidos.length > 0) {
            let labels = [],
              values = [];
            for (const key in this.atendidos) {
              labels.push(this.atendidos[key].tipotramite);
              values.push(this.atendidos[key].cantidad * 1);
            }
            this.chartTramites = {
              chart: {
                type: "bar",
              },
              title: {
                text: "Tipo de Trámites Realizados",
              },
              xAxis: {
                categories: labels,
                title: {
                  text: null,
                },
              },
              yAxis: {
                min: 0,
                title: {
                  text: "Cantidad (#)",
                  align: "high",
                },
                labels: {
                  overflow: "justify",
                },
              },
              plotOptions: {
                bar: {
                  dataLabels: {
                    enabled: true,
                    format: "{point.y}",
                  },
                },
              },
              legend: {
                enabled: false,
                layout: "vertical",
                align: "right",
                verticalAlign: "top",
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 0,
                backgroundColor: "#FFFFFF",
                shadow: true,
              },
              credits: {
                enabled: false,
              },
              series: [
                {
                  name: "Tipo de trámite",
                  colorByPoint: true,
                  data: values,
                },
              ],
              exporting: {
                buttons: {
                  contextButton: {
                    menuItems: [
                      {
                        text: "Imprimir",
                        textKey: "printChart",
                        onclick: function () {
                          this.print();
                        },
                      },
                      {
                        separator: true,
                      },
                      {
                        text: "Descargar PNG",
                        textKey: "downloadPNG",
                        onclick: function () {
                          this.exportChart();
                        },
                      },
                      {
                        text: "Descargar JPG",
                        textKey: "downloadJPEG",
                        onclick: function () {
                          this.exportChart({
                            type: "image/jpeg",
                          });
                        },
                      },
                    ],
                  },
                },
              },
            };
          } else {
            this.message = "No existen registros.";
          }
        })
        .catch((error) => {
          this.loadingOperador = false;
          this.message = "No se pudo obtener registros.";
          console.error("Error al cargar registros", error);
        });
    },

    toast(mcolor, mtext) {
      this.snack.color = mcolor;
      this.snack.text = mtext;
      this.snack.state = true;
    },
  },
};
</script>
